///include /assets/js/plugins/network-property-set.js
"use strict";

if($.ajaxSetup) {
    $.ajaxSetup({
        cache: navigator.userAgent.match(/msie/i) ? false : undefined,
    });
}

class UserPreferences {
    constructor() {
        this._v = 0
        /** @type {?Promise} */
        this._loading = null
    }
    /**
     * Set this to true when the vue-code-mirror component is freezing the page
     */
    get disableCodemirror() {
        return this._get("disableCodemirror")
    }
    set disableCodemirror(v) {
        this._set("disableCodemirror", v)
    }
    get indexSort() {
        return this._get("indexSort")
    }
    set indexSort(v) {
        this._set("indexSort", v)
    }
    get openedSB() {
        return this._get("openedSB")
    }
    set openedSB(v) {
        this._set("openedSB", v)
    }
    get overviewSort() {
        return this._get("overviewSort")
    }
    set overviewSort(v) {
        this._set("overviewSort", v)
    }
    get sOverviewSort() {
        return this._get("sOverviewSort")
    }
    set sOverviewSort(v) {
        this._set("sOverviewSort", v)
    }
    get loading() {
        if(!this._loading) {
            if(sessionStorage.includesPrefs) {
                this._loading = Promise.resolve()
            } else {
                this._loading = this.importPreferences()
            }
        }
        return this._loading
    }
    _get(n) {
        if(this._v == 1000) {
            // This is here to make sure that Vue notices a change!
            console.log("Busy object!")
        }
        if(this.loading && sessionStorage[`pref.${n}`] !== undefined) {
            return JSON.parse(sessionStorage[`pref.${n}`])
        } else {
            return undefined
        }
    }
    _set(n, v) {
        this._v++
        if(v !== undefined) {
            sessionStorage[`pref.${n}`] = JSON.stringify(v)
        } else {
            sessionStorage[`pref.${n}`] = v
        }
        this.exportPreferences()
    }
    async exportPreferences() {
        const out = {}
        for(const [k, v] of Object.entries(sessionStorage)) {
            if(k.match(/^pref[.]/)) {
                out[k] = v
            }
        }
        /** @optional /a/userPreference */
        return await $.ajax({
            method: "put",
            url: `/a/userPreference`,
            contentType: "application/json",
            data: JSON.stringify(out),
        })
    }
    async importPreferences() {
        try {
            const prefs = await $.ajax(`/a/userPreference`)
            for(const [k, v] of Object.entries(prefs)) {
                if(k.match(/^pref[.]/)) {
                    sessionStorage[k] = v
                }
            }
        } catch(e) {
            // Do nothing
        }
        this._v++
        sessionStorage.includesPrefs = "1"
    }
}

class User extends NetworkPropertySet {
    static get inst() {
        if(!this._inst) {
            this._inst = new User()
        }
        return this._inst
    }
    constructor() {
        super()
        /** @type {?{avatarUrl: ?string, company_name: ?string, email: string, person_name: ?string}} */
        this.contact = null
        /** @type {?boolean} */
        this.hasAdditionalLocation = null
        /** @type {?boolean} */
        this.hasCloudServer = null
        /** @type {?boolean} */
        this.hasAutoscaleAllowance = null
        /** @type {?boolean} */
        this.hasReseller = null
        /** @type {?boolean} */
        this.hasTimelineBackups = null
         /** @type {?boolean} */
         this.hasTimelineBackupsPro = null
        /** @type {?boolean} */
        this.hasVNS = null
        /** @type {?boolean} */
        this.hasWHMCS = null
        /** @type {?boolean} */
        this.hasWordpressUnlimited = null;
        /** @type {?boolean} */
        this.hasPackageAllowance = null;
        /** @type {?GET.Auth.id.output} */
        this.identityInfo = null

        this.preferences = new UserPreferences()

        /**
         * @type {?GET.Services.reseller.output[0]}
         */
        this.resellerType = null

        /**
         * @type {?GET.Services.reseller.usageData.output[0]}
         */
        this.resellerUsage = null

        if(sessionStorage.hasOwnProperty("hasAdditionalLocation")) {
            this.hasAdditionalLocation = !!sessionStorage.hasAdditionalLocation
        }
        if(sessionStorage.hasOwnProperty("hasReseller")) {
            this.hasReseller = !!sessionStorage.hasReseller
        }
        if(sessionStorage.hasOwnProperty("hasAutoscaleAllowance")) {
            this.hasAutoscaleAllowance = !!sessionStorage.hasAutoscaleAllowance
        }
        if(sessionStorage.hasOwnProperty("hasTimelineBackups")) {
            this.hasTimelineBackups = !!sessionStorage.hasTimelineBackups
        }
        if(sessionStorage.hasOwnProperty("hasTimelineBackupsPro")) {
            this.hasTimelineBackupsPro = !!sessionStorage.hasTimelineBackupsPro
        }
        if(sessionStorage.hasOwnProperty("hasVNS")) {
            this.hasVNS = !!sessionStorage.hasVNS
        }
        if(sessionStorage.hasOwnProperty("hasCloudServer")) {
            this.hasVNS = !!sessionStorage.hasCloudServer
        }
        if(sessionStorage.hasOwnProperty("hasWHMCS")) {
            this.hasWHMCS = !!sessionStorage.hasWHMCS
        }
        if(sessionStorage.hasOwnProperty("hasMailboxNearCapacity")) {
            this.hasMailboxNearCapacity = !!sessionStorage.hasMailboxNearCapacity
        };
        if(sessionStorage.hasOwnProperty("hasPackageAllowance")) {
            this.hasPackageAllowance = !!sessionStorage.hasPackageAllowance
        }
        this.hasAgencyHub = undefined;
    }

    get canUseWebsiteTurbo() {
        return this.hasReseller || this.hasWordpressUnlimited || this.hasPackageAllowance
    }

    get networkPropertyHandlers() {
        return {
            contact: async () => {
                try {
                    return await $.ajax(`/a/userContact/me`)
                } catch(jqXHR) {
                    if(jqXHR.status == 404) {
                        return {
                            avatarUrl: null,
                            company_name: null,
                            email: null,
                            person_name: null,
                        }
                    } else {
                        return null
                    }
                }
            },
            hasAdditionalLocation: async () => {
                /** @optional /a/service/package_allowance */
                let v = null
                let val = null;
                try {
                    val = await $.ajax(`/a/service/dc_location`)
                } catch(e) {
                    //
                }
                return this.cacheFlag("AdditionalLocation", (val ? val.length > 0 : false))
            },
            hasPackageAllowance: async () => {
                /** @optional /a/service/package_allowance */
                let v = null
                let val = null;
                try {
                    val = await $.ajax(`/a/service/package_allowance`)
                } catch(e) {
                    //
                }
                return this.cacheFlag("PackageAllowance", (val ? val.length > 1 : false))
            },
            hasReseller: async () => {
                /** @optional /a/reseller */
                let v = null
                let val = null;
                try {
                    v = await $.ajax(`/a/reseller`)
                    val = v.find( i => i.spec != null );
                } catch(e) {
                    //
                }
                return this.cacheFlag("Reseller", val)
            },
            hasAutoscaleAllowance: async () => {
                /** @optional /a/service/autoscale_allowance */
                let v = null
                let val = null;
                try {
                    v = await $.ajax(`/a/service/autoscale_allowance`)
                    val = v.find( i => i.productSpec != null );
                } catch(e) {
                    //
                }
                return this.cacheFlag("AutoscaleAllowance", val)
            },
            hasAgencyHub: async () => {
                /** @optional /a/reseller */
                let v = null
                let val = null;
                try {
                    v = await $.ajax(`/a/reseller`)
                    val = v.find( i => i.agencyhubEnabled );
                } catch(e) {
                    //
                }
                return this.cacheFlag("AgencyHub", val)
            },
            hasMVPS: async () => {
                let v = null
                try {
                    v = await $.ajax(`/a/managed_vps`)
                } catch(e) {
                    //
                }
                var mvps = !!v.find(server => !server.isCloud)
                return mvps;
            },
            hasCloudServer: async () => {
                let v = null
                try {
                    v = await $.ajax(`/a/managed_vps`)
                } catch(e) {
                    //
                }
                var cloud = !!v.find(server => server.isCloud)
                return this.cacheFlag("CloudServer",cloud);
            },
            hasTimelineBackups: async () => {
                /** @optional /a/timeline_storage */
                let v = null
                try {
                    v = await this.collectionLength(`/a/timeline_storage`)
                } catch(e) {
                    //
                }
                return this.cacheFlag("TimelineBackups", v)
            },
            hasTimelineBackupsPro: async () => {
                /** @optional /a/timeline_storage */
                let v = null
                try {
                    v = await $.ajax({
                        url: `/a/timeline_storage`,
                        data: {
                            selector: {
                                productSpec: { "$regex": "pro" }
                            }
                        }
                    })
                } catch(e) {
                    //
                }
                return this.cacheFlag("TimelineBackupsPro", (v.length > 0))
            },
            hasVNS: async () => {
                /** @optional /a/personal_nameserver */
                let v = null
                try {
                    v = await this.collectionLength(`/a/personal_nameserver`)
                } catch(e) {
                    //
                }
                return this.cacheFlag("VNS", v)
            },
            hasWHMCS: async () => {
                /** @optional /a/whmcs_license */
                let v = null
                try {
                    v = await this.conditionalCollectionLength(`/a/whmcs_license`, "manageVPS")
                } catch(e) {
                    //
                }
                return this.cacheFlag("WHMCS", v)
            },
            hasWordpressUnlimited: async () => {
                /** @optional /a/wordpress_allowance */
                let v = null
                try {
                    v = await $.ajax({
                        url: `/a/wordpress_allowance`,
                        data: {
                            selector: {
                                productSpec: "web-wordpressagsetINF"
                            }
                        }
                    })
                } catch(e) {
                    //
                }
                return this.cacheFlag("WordpressUnlimited", (v.length > 0))
            },
            identityInfo: async () => await $.ajax(`/a/id`),
            resellerType: async () => {
                try {
                    const rt = await $.ajax(`/a/reseller`)
                    if(rt && rt.length) {
                        return rt[0]
                    } else {
                        return null
                    }
                } catch(jqXHR) {
                    return null
                }
            },
            resellerPlatformTypes: async () => {
                try {
                    const rt = await $.ajax(`/a/reseller`)
                    if(rt && rt.length) {
                        return rt[0].platformTypes
                    } else {
                        return null
                    }
                } catch(jqXHR) {
                    return null
                }
            },
            resellerUsage: async () => await $.ajax(`/a/reseller/*/usageData`),
        }
    }
    /**
     * @type {?string} eg. "customer"
     */
    get type() {
        return this.identityInfo && this.identityInfo.content.replace(/:.*/, "")
    }
    get username() {
        if(this.contact) {
            return this.contact.email;
        } else {
            return null;
        }
    }
    get userRef() {
        return this.identityInfo && this.identityInfo.content
    }
    /**
     * Caches a flag value in sessionStorage
     *
     * @param {string} n eg. "Reseller"
     * @param {*} v
     * @returns {boolean}
     */
    cacheFlag(n, v) {
        sessionStorage[`has${n}`] = v ? "1" : ""
        return !!sessionStorage[`has${n}`]
    }
    /**
     * Returns a promise which will be true if the user has all the scopes.
     *
     * See also scopesInclude() for the non-promise version.
     *
     * @param {...string} scopes
     * @returns {Promise<boolean>}
     */
    async checkScopes(...scopes) {
        const available_scopes =
            (await this.preloadSingle("identityInfo")).scope.map(
                sv => sv.replace(/=.*/, "")
            )
        return scopes.every(
            s => available_scopes.includes(s)
        )
    }
    /**
     *
     * @param {string} url
     * @param  {...string} scopes
     */
    async conditionalCollectionLength(url, ...scopes) {
        if(await this.checkScopes(...scopes)) {
            return this.collectionLength(url)
        } else {
            return 0
        }
    }
    /**
     * Returns true if the user has all the scopes.
     *
     * See also checkScopes() for the promise version.
     *
     * @param {...string} scopes
     * @returns {?boolean}
     */
    scopesInclude(...scopes) {
        const info = this.identityInfo
        if(info) {
            return scopes.every(
                s => info.scope.map(
                    sv => sv.replace(/=.*/, "")
                ).includes(s)
            )
        } else {
            return null
        }
    }
    /**
     * Uncaches some flags. If you don't supply names, it will be all of them.
     * They will all be subject to reload, so you shouldn't do this until
     * they're in the correct upstream state.
     *
     * @param {boolean} [keep]
     * @param {string[]} [names] eg. ["Reseller"]
     * @returns {void}
     */
    uncacheFlags(
        keep = false,
        names = ["Reseller", "TimelineBackups", "VNS", "WHMCS"]
    ) {
        let properties = names.map(n => `has${n}`).filter(
            n => n in this
        )
        properties.forEach(p => {
            delete sessionStorage[p]
            if(!keep) {
                this[p] = null
            }
        })
        this.reload(properties)
    }
}
